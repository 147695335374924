import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import welcome from "../../images/welcome.jpg"
import contact from "../../images/contact.jpg"
import { Link } from "gatsby"

const Checklist = ({ children }) => <ul className="text">{children}</ul>

const ChecklistItem = ({ title, description }) => (
  <li className="p-2 flex">
    <span className="mr-4 text-xs inline-flex w-6 h-6 items-center justify-center text-green-500 rounded-full flex-shrink-0">
      <FontAwesomeIcon icon={faCheck} />
    </span>
    <main>
      <h3 className="font-bold">{title}</h3>
      <p className="text-gray-700">{description}</p>
    </main>
  </li>
)

export default function Hoveniersbedrijf() {
  return (
    <Layout>
      <Seo title="Hoveniersbedrijf" />
      <div className="container py-8">
        <h1 className="h1 breadcrumbs">
          <span className="breadcrumb">
            <Link to="/" className="link">
              Home
            </Link>
          </span>
          <span className="breadcrumb">::</span>
          <span className="breadcrumb">
            Hoveniersbedrijf website laten maken
          </span>
        </h1>
        <p className="paragraph">
          Laat een professionele website voor uw hoveniersbedrijf op maat
          ontwerpen door Thomas' Webdesign.
        </p>

        <section className="row sm:flex-no-wrap mt-4">
          <aside className="column sm:w-auto">
            <img
              src={welcome}
              className="w-full sm:w-96 rounded"
              alt="Welkomspagina"
            />
          </aside>
          <main className="column">
            <h2 className="h2">Welkomspagina</h2>
            <p className="paragraph">
              De eerste pagina die uw bezoekers te zien krijgen. Ex minim
              consectetur culpa enim enim irure id anim minim voluptate labore.
              Qui cillum nulla enim ut nulla deserunt qui dolor. In aliquip amet
              eiusmod esse ipsum quis esse.
            </p>
            <p className="paragraph">
              Dolore enim laboris anim tempor reprehenderit nulla in elit
              aliquip ullamco ad cupidatat fugiat deserunt. Mollit aliqua
              occaecat excepteur consequat anim dolore sint sunt elit sint sit
              sint.
            </p>
          </main>
        </section>

        <section className="row sm:flex-no-wrap mt-4">
          <main className="column">
            <h2 className="h2 flex items-center">Contactpagina</h2>
            <p className="paragraph">
              Wellicht de belangrijkste pagina op uw site: de contactpagina. Ex
              minim consectetur culpa enim enim irure id anim minim voluptate
              labore. Qui cillum nulla enim ut nulla deserunt qui dolor. In
              aliquip amet eiusmod esse ipsum quis esse.
            </p>
            <Checklist>
              <ChecklistItem
                title="Contactformulier"
                description="Wanneer een bezoeker dit formulier invult ontvangt u
                      direct een e-mail notificatie."
              ></ChecklistItem>
              <ChecklistItem
                title="Google Maps"
                description="Een Google Maps kaartje met daarop de locatie van uw bedrijf zodat klanten u nog beter weten te vinden."
              ></ChecklistItem>
            </Checklist>
          </main>
          <aside className="column sm:w-auto">
            <img
              src={contact}
              className="w-full sm:w-72 rounded"
              alt="Neem contact op"
            />
          </aside>
        </section>

        <footer className="bg-gray-300 p-8 mt-8 rounded-sm">
          <label className="mb-4 block">
            <h1 className="h2">
              Ook een professionele website laten ontwerpen voor uw
              hoveniersbedrijf?
            </h1>
            <p className="paragraph">
              Neem contact met ons op we kijken samen naar de mogelijkheden. Vul
              uw e-mailadres in voor een vrijblijvend gesprek.
            </p>
            <input
              className="form-input block w-full"
              name="name"
              type="text"
              placeholder="Uw e-mailadres"
            />
          </label>
          <Link to="/contact" className="button mt-2 inline-flex items-center">
            Plan een afspraak
          </Link>
        </footer>
      </div>
    </Layout>
  )
}
